// import React from "react"
// import { Provider } from "react-redux"

// import createStore from "./src/state/createStore"

// // eslint-disable-next-line react/display-name,react/prop-types
// // eslint-disable-next-line import/no-anonymous-default-export
// export default ({ element }) => {
//   // Instantiating store in `wrapRootElement` handler ensures:
//   //  - there is fresh store for each SSR page
//   //  - it will be called only once in browser, when React mounts
//   const store = createStore()
//   return <Provider store={store}>{element}</Provider>
// }

import React from "react"
import { store } from "./src/state/createStore"
import { Provider } from "react-redux"
import { AuthWrapper } from "./src/state/authSlice"

// ReactDOM.render(
//   <Provider store={store}>
//     <App />
//   </Provider>,
//   document.getElementById("root")
// )

const App = ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  return (
    <Provider store={store}>
      <>
        <AuthWrapper />
        {element}
      </>
    </Provider>
  )
}
export default App
