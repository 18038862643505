import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { LeadChannel, RegCertCarCategory } from "../../apiHelpers/types"
import {
  AnmeldungPage,
  anmeldungPageNames,
} from "../../components/anmeldung/anmeldungRouter"
import { makeId } from "../../helpers"
import {
  channelToRate,
  extendUtmDictString,
  paidUtmToReturnVal,
  returnLeadChannel,
} from "../../helpers/Tracking/tracking"
import { BankAccount } from "../../services/types/bankAccount"
import { TempDealData } from "../../services/types/tempDealData"

export type Branding =
  | "smartificate"
  | "tibber"
  | "efahrer"
  | "nordsw"
  | "flensburg-energie"
  | "emilfrey"
  | "wechselpilot"

export type AnmeldungState = {
  entrypoint: AnmeldungPage
  branding: Branding
  brand?: string
  regCertCarCategory?: RegCertCarCategory
  isCompanyCar: boolean
  isBatchMode: boolean
  referralOwn: string
  referralFrom?: string
  leadChannel: LeadChannel
  dealId?: string
  regCertificates: string[]
  utm?: string
  bankAccount?: BankAccount
  dealerId?: string
  signedAgb: boolean
  companyVerified?: boolean
  registrationPlates?: string[]
}

export const initialtate: AnmeldungState = {
  entrypoint: anmeldungPageNames.start,
  branding: "smartificate",
  isCompanyCar: false,
  isBatchMode: false,
  referralOwn: makeId(8),
  leadChannel: {
    channel: "default",
    rateOptions: channelToRate.default,
    utm: "{}",
  },
  regCertificates: [],
  signedAgb: false,
}

const anmeldungSlice = createSlice({
  name: "anmeldung",
  initialState: initialtate,
  reducers: {
    setBrand: (state: AnmeldungState, action: PayloadAction<string>) => {
      state.brand = action.payload
    },
    setRegCertCarCategory: (
      state: AnmeldungState,
      action: PayloadAction<RegCertCarCategory>
    ) => {
      state.regCertCarCategory = action.payload
    },
    setIsCompanyCar: (
      state: AnmeldungState,
      action: PayloadAction<boolean>
    ) => {
      state.isCompanyCar = action.payload
    },
    setDealId: (state: AnmeldungState, action: PayloadAction<string>) => {
      state.dealId = action.payload
    },
    extendUtm: (state: AnmeldungState, action: PayloadAction<string>) => {
      let combinedUtmRaw = "{}"

      combinedUtmRaw = extendUtmDictString(
        localStorage.getItem("utm")!,
        action.payload
      )
      if (combinedUtmRaw && combinedUtmRaw !== "{}") {
        localStorage.setItem("utm", combinedUtmRaw)
        state.utm = combinedUtmRaw
        const leadChannel = returnLeadChannel(combinedUtmRaw, false)
        // in case of dealerId, the leadChannel is loaded from hubspot
        if (leadChannel.dealerId && leadChannel.dealerId !== "") {
          state.dealerId = leadChannel.dealerId
        } else {
          state.leadChannel = leadChannel
          state.utm = combinedUtmRaw
        }
        if (leadChannel.channel === "referral") {
          state.referralFrom = leadChannel.referralFrom
        }
      }
    },
    setReferralOwn: (state: AnmeldungState, action: PayloadAction<string>) => {
      state.referralOwn = action.payload
    },
    setReferralFrom: (state: AnmeldungState, action: PayloadAction<string>) => {
      state.referralFrom = action.payload
    },
    setDealerId: (state: AnmeldungState, action: PayloadAction<string>) => {
      state.dealerId = action.payload
    },
    addRegCertificate: (
      state: AnmeldungState,
      action: PayloadAction<string>
    ) => {
      state.regCertificates.push(action.payload)
    },
    setBankAccount: (
      state: AnmeldungState,
      action: PayloadAction<BankAccount>
    ) => {
      state.bankAccount = action.payload
    },
    setBatchMode: (state: AnmeldungState, action: PayloadAction<boolean>) => {
      state.isBatchMode = action.payload
    },
    setEntrypoint: (
      state: AnmeldungState,
      action: PayloadAction<AnmeldungPage>
    ) => {
      state.entrypoint = action.payload
    },
    setBranding: (state: AnmeldungState, action: PayloadAction<Branding>) => {
      state.branding = action.payload
      // state.leadChannel = paidUtmToReturnVal(action.payload, "")
    },
    setLeadChannelByChannelName: (
      state: AnmeldungState,
      action: PayloadAction<string>
    ) => {
      state.leadChannel = paidUtmToReturnVal(action.payload, "")
    },
    setLeadChannel: (
      state: AnmeldungState,
      action: PayloadAction<LeadChannel>
    ) => {
      state.leadChannel = action.payload
    },
    setSignedAgb: (state: AnmeldungState, action: PayloadAction<boolean>) => {
      state.signedAgb = action.payload
    },
    setCompanyVerified: (
      state: AnmeldungState,
      action: PayloadAction<boolean>
    ) => {
      state.companyVerified = action.payload
    },

    setToConfirmRegistrationPlates: (
      state: AnmeldungState,
      action: PayloadAction<string[]>
    ) => {
      state.registrationPlates = action.payload
    },

    resetState: (state: AnmeldungState, action: PayloadAction<void>) => {
      return {
        ...initialtate,
        utm: state.utm,
        leadChannel: state.leadChannel,
        entrypoint: state.entrypoint,
        referralFrom: state.referralFrom,
        dealerId: state.dealerId,
      }
    },
    setTempDealData: (
      state: AnmeldungState,
      action: PayloadAction<TempDealData>
    ) => {
      let combinedUtmRaw = "{}"

      if (action.payload.utm) {
        combinedUtmRaw = extendUtmDictString(
          localStorage.getItem("utm")!,
          action.payload.utm
        )
      }
      if (action.payload.isCompanyCar !== undefined) {
        state.isCompanyCar = action.payload.isCompanyCar
      }
      if (action.payload.regCertCarCategory !== undefined) {
        // @ts-ignore
        state.regCertCarCategory = action.payload.regCertCarCategory
      }
      if (combinedUtmRaw && combinedUtmRaw !== "{}") {
        localStorage.setItem("utm", combinedUtmRaw)
        state.utm = combinedUtmRaw
        state.leadChannel = returnLeadChannel(combinedUtmRaw, false)
      }
      if (action.payload.leadChannel) {
        state.leadChannel = JSON.parse(action.payload.leadChannel)
      }
      state.dealId = action.payload.dealId
      state.brand = action.payload.brand
      state.referralOwn = action.payload.referralOwn
    },
  },
})

export const {
  setBrand,
  setRegCertCarCategory,
  setIsCompanyCar,
  setDealId,
  addRegCertificate,
  setReferralOwn,
  setReferralFrom,
  extendUtm,
  setBankAccount,
  setBatchMode,
  resetState,
  setTempDealData,
  setEntrypoint,
  setBranding,
  setLeadChannelByChannelName,
  setLeadChannel,
  setDealerId,
  setSignedAgb,
  setCompanyVerified,
  setToConfirmRegistrationPlates,
} = anmeldungSlice.actions

export default anmeldungSlice.reducer
