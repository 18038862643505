import React from "react"
import { Router, Redirect } from "@reach/router"
import { AnimatePresence } from "framer-motion"

import { RouteComponentProps } from "@reach/router"
import { useSelector } from "react-redux"
import { RootState, useAppDispatch } from "../../state/createStore"
import {
  resetState,
  setTempDealData,
  extendUtm,
  setBatchMode,
  Branding,
  setLeadChannel,
} from "../../state/anmeldung/anmeldungSlice"
import queryString from "query-string"
import {
  useDealerLeadChannelMutation,
  useTempDealDataMutation,
} from "../../services/smartificateApi"
import { TempDealData } from "../../services/types/tempDealData"
import Loading from "./components/Loading"
import Fehler from "./components/Fehler"
import FehlerPage from "./pages/Fehler"
import { navigate } from "@reach/router"
import FertigAenderungen from "./pages/FertigAenderungen"
import FahrzeugscheinAktuell from "./pages/FahrzeugscheinAktuell"

type Mode = "Normal" | "Batch" | "Dealer"

const AnmeldungRouter = (
  props: RouteComponentProps<{ mode?: Mode; dataTheme?: Branding }>
) => {
  const dispatch = useAppDispatch()
  const [getTempData, { isLoading, isError }] = useTempDealDataMutation()

  const [loadRoutes, setLoadRoutes] = React.useState(false)
 
  const anmeldung = useSelector((state: RootState) => state.anmeldung)
  const [getDealerLeadChannel, { isLoading: isDealerLeadChannelLoading }] =
    useDealerLeadChannelMutation()
  // const [getReferralRateOption, { isLoading: isReferralRateOptionLoading }] =
  //   useReferralRateOptionMutation()

  React.useEffect(() => {
    dispatch(resetState())
    if (props.mode === "Batch" || props.mode === "Dealer")
      dispatch(setBatchMode(true))

    if (props?.location?.search) {
      dispatch(extendUtm(props?.location?.search))
      const utmParams = queryString.parse(props.location.search)
      console.log("has utm", utmParams)
      if (utmParams.dealId && typeof utmParams.dealId === "string") {
        getTempData(utmParams.dealId)
          .unwrap()
          .then((data: TempDealData) => {
            // Support HubSpot Links to forward to elektrobonus based on website property
            if (
              data.website !== "smartificate" &&
              (props?.location?.href.includes("smartificate") ||
                props?.location?.href.includes("localhost"))
            ) {
              if (data.website === "elektrobonus") {
                navigate(
                  "/elektrobonus" +
                    props?.location?.pathname +
                    props?.location?.search
                )
              }
            } else {
              dispatch(setTempDealData(data))
              // if (data.utm) dispatch(extendUtm(data.utm))
              setLoadRoutes(true)
            }
          })
          .catch(() => {})
      } else {
        setLoadRoutes(true)
      }
    } else {
      setLoadRoutes(true)
    }
  }, [])

  React.useEffect(() => {
    if (anmeldung.dealerId) {
      getDealerLeadChannel(anmeldung.dealerId)
        .unwrap()
        .then((data) => {
          dispatch(setLeadChannel(data.dealerLeadChannel))
        })
        .catch(() => {})
    }
  }, [anmeldung.dealerId, dispatch, getDealerLeadChannel])

  

  return (
    <div
      className="mx-auto text-white"
      id="mainFlow"
      data-theme={props.dataTheme ? props.dataTheme : "flowTheme"}
    >
      <AnimatePresence>
        {isLoading ||
        isDealerLeadChannelLoading ||
        (!loadRoutes && !isError) ? (
          <Loading />
        ) : isError ? (
          <Fehler errorMsg="Hast Du diesen Link ggf. bereits verwendet gehabt?" />
        ) : (
          <Router>
            {/* earlier /banner was the start page */}
            <Redirect from="/*" to={"/kundenbereich"} noThrow={true} default />
            <FertigAenderungen path={anmeldungPaths.fertigAenderungen} />
            <FehlerPage path={anmeldungPaths.fehler} />
            <FahrzeugscheinAktuell
              path={anmeldungPaths.fahrzeugscheinAktuell}
            />
          </Router>
        )}
      </AnimatePresence>
    </div>
  )
}
export default AnmeldungRouter

export const defaultBase = "/anmeldung"

export type AnmeldungPage =
  | "vertrag"
  | "vertragErfolgreich"
  | "aenderungenErfolgreich"
  | "fehler"
  | "fahrzeugscheinAktuell"
  | "fertigAenderungen"

export type AnmeldungPages = {
  vertrag: AnmeldungPage
  vertragErfolgreich: AnmeldungPage
  aenderungenErfolgreich: AnmeldungPage
  fehler: AnmeldungPage
  fahrzeugscheinAktuell: AnmeldungPage
  fertigAenderungen: AnmeldungPage
}

export const anmeldungPageNames: AnmeldungPages = {
  vertrag: "vertrag",
  vertragErfolgreich: "vertragErfolgreich",
  aenderungenErfolgreich: "aenderungenErfolgreich",
  fehler: "fehler",
  fahrzeugscheinAktuell: "fahrzeugscheinAktuell",
  fertigAenderungen: "fertigAenderungen",
}

export const anmeldungRoutes: AnmeldungPages = {
  ...anmeldungPageNames,
}
Object.entries(anmeldungRoutes).forEach(
  ([k, v]) => (anmeldungRoutes[k] = "../" + v)
)

export const anmeldungRoutesAbs: AnmeldungPages = {
  ...anmeldungRoutes,
}
Object.entries(anmeldungRoutesAbs).forEach(
  ([k, v]) => (anmeldungRoutesAbs[k] = defaultBase + v.substring(2))
)

export const anmeldungPaths: AnmeldungPages = { ...anmeldungRoutes }
Object.entries(anmeldungPaths).forEach(
  ([k, v]) => (anmeldungPaths[k] = v.substring(2))
)
