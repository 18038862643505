/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import "./src/css/index.css"
import wrapWithProvider from "./wrapRootElement"
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
export const wrapRootElement = wrapWithProvider

export const onClientEntry = function (_, pluginParams) {
  Sentry.init({
    dsn: "https://daa1477778124c7d86d5cb227316669d@o1050773.ingest.sentry.io/6033396",
    integrations: [new Integrations.BrowserTracing()],
    normalizeDepth: 4,

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.05,
  })
}

export const onRouteUpdate = function () {
  // Don't track while developing.
  if (process.env.NODE_ENV === `production` && typeof fbq === `function`) {
    // eslint-disable-next-line no-undef
    fbq("track", "ViewContent")
  }
}
