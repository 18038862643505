import React from "react"
import { twMerge } from "tailwind-merge"

const Button = (props: {
  onClick?: () => void
  text?: string
  classes?: string
  className?: string
  secondary?: boolean
  disabled?: boolean
  children?: JSX.Element | JSX.Element[]
  type?: "button" | "submit" | "reset" | undefined
}) => (
  <button
    className={twMerge(
      `
    mx-4 my-3 py-4 px-8 w-full bg-primary tracking-tight font-bold rounded-md text-primary-content border-primary
    md:hover:border-primary md:hover:shadow-lg md:hover:bg-primary md:hover:scale-105
    transform transition duration-300 ease-in-out`,

      `${props.secondary ? "bg-transparent border text-base-content" : ""}`,
      props.classes,
      props.className
    )}
    onClick={props.onClick}
    disabled={props.disabled}
    type={props.type}
  >
    {props.text}
    {props.children}
  </button>
)

export default Button
