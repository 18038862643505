import React from "react"
import { RouteComponentProps } from "@reach/router"
import Layout from "./Layout"
import { SubSubTitle, Title } from "../../anmeldung/components/Titles"
import Button from "./Button"
import { navigate } from "gatsby"

const Fehler = ({ errorMsg }: RouteComponentProps<{ errorMsg?: string }>) => {
  return (
    <Layout>
      <>
        <Title className="text-center">Oh nein!</Title>
        <SubSubTitle className="text-center">
          <>
            Leider ist etwas schief gelaufen. Bitte kontaktiere uns unter{" "}
            <a href="mailto:support@smartificate.de" className="link">
              support@smartificate.de
            </a>
          </>
        </SubSubTitle>
        {errorMsg ? (
          <SubSubTitle className="text-center">{errorMsg}</SubSubTitle>
        ) : (
          ""
        )}
        <Button
          text="Zur Startseite"
          onClick={() => navigate("/")}
          className="w-full mx-0"
        />
      </>
    </Layout>
  )
}
export default Fehler
