import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react"
import {
  Customer,
  DealerDeal,
  DealStage,
  LeadChannel,
  RateOptions,
} from "../apiHelpers/types"
import { isUser, setAuthState, User } from "../state/authSlice"
import { RootState } from "../state/createStore"
import { ApiCustomerGetExtendableContracts } from "./types/apiCustomerGetExtendableContracts"
import { ApiCustomerSetExtendableContracts } from "./types/apiCustomerSetExtendableContracts"
import { BankAccount } from "./types/bankAccount"
import { Company } from "./types/company"
import { ContractSubmission } from "./types/ContractSubmission"
import { CustomerAddUserEmails } from "./types/customerAddUserEmails"
import { CustomerNewDealSubmission } from "./types/customerNewDeal"
import { DealerAddCustomerCar } from "./types/dealerAddCustomerCar"
import { RegCertSubmission } from "./types/RegCertSubmission"
import { Registration } from "./types/registration"
import { Signup } from "./types/signup"
import { TempDealData } from "./types/tempDealData"
import { TibberPaymentType } from "./types/tibberPaymentType"
import { TokenRequest } from "./types/tokenRequest"

const baseQuery = fetchBaseQuery({
  baseUrl: "/.netlify/functions/",
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const authState = (getState() as RootState).auth
    if (isUser(authState)) {
      const token = authState.token
      headers.set("authorization", `Bearer ${token}`)
    }
    return headers
  },
})
const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)
  console.log(result)
  if (result.error && result.error.status === 401) {
    api.dispatch(setAuthState("LoggedOut"))
  }

  const mayToken: string | undefined = result?.meta?.response?.headers
    ?.get("newtoken")
    ?.substring(7)
  if (mayToken) {
    const user: User = { token: mayToken, userType: "customer" }
    api.dispatch(setAuthState(user))
  }
  return result
}
export const smartificateApi = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    "Customer",
    "ClientCars",
    "ExtendableContracts",
    "apiDealGetRegCertNames",
  ],
  endpoints: (builder) => ({
    login: builder.mutation<
      { channel: string; channelPw: string },
      TokenRequest
    >({
      query: (credentials) => ({
        url: "tokenRequest",
        method: "POST",
        body: credentials,
      }),
    }),
    registration: builder.mutation<{ dealId: string }, Registration>({
      query: (credentials) => ({
        url: "registration",
        method: "POST",
        body: credentials,
      }),
    }),
    signup: builder.mutation<{}, Signup>({
      query: (body) => ({
        url: "signup",
        method: "POST",
        body,
      }),
    }),
    dealerAddCustomerCar: builder.mutation<TempDealData, DealerAddCustomerCar>({
      query: (body) => ({
        url: "dealerAddCustomerCar",
        method: "POST",
        body,
      }),
    }),

    customer: builder.query<Customer, any>({
      query: () => `customer`,
      providesTags: ["Customer"],
    }),
    tempDealData: builder.mutation<TempDealData, string>({
      query: (dealId: string) => `tempDealData/?dealId=${dealId}`,
    }),
    referralRateOption: builder.mutation<{ rateOptions: RateOptions }, string>({
      query: (referralCode: string) =>
        `referralRateOption/?referralCode=${referralCode}`,
    }),
    dealerLeadChannel: builder.mutation<
      { dealerLeadChannel: LeadChannel },
      string
    >({
      query: (dealerId: string) => `dealerLeadChannel/?dealerId=${dealerId}`,
    }),
    bankAccount: builder.mutation<void, BankAccount>({
      query: (body) => {
        return {
          url: `bankAccount`,
          method: "POST",
          body,
        }
      },
      invalidatesTags: ["Customer"],
    }),
    customerAddUserEmails: builder.mutation<void, CustomerAddUserEmails>({
      query: (body) => {
        return {
          url: `customerAddUserEmails`,
          method: "POST",
          body,
        }
      },
      invalidatesTags: ["Customer"],
    }),
    toggleTaxFreeMode: builder.mutation<void, void>({
      query: () => {
        return {
          url: `toggleTaxFreeMode`,
          method: "POST",
          body: {},
        }
      },
      invalidatesTags: ["Customer"],
    }),
    company: builder.mutation<void, Company>({
      query: (body) => {
        return {
          url: `company`,
          method: "POST",
          body,
        }
      },
      invalidatesTags: ["Customer"],
    }),
    contractSubmission: builder.mutation<void, ContractSubmission>({
      query: (body) => {
        return {
          url: `contractSubmission`,
          method: "POST",
          body,
        }
      },
      invalidatesTags: ["Customer"],
    }),
    regCertSubmission: builder.mutation<void, RegCertSubmission>({
      query: (body) => {
        return {
          url: `regCertSubmission`,
          method: "POST",
          body,
        }
      },
      invalidatesTags: ["Customer"],
    }),
    tibberPaymentType: builder.mutation<void, TibberPaymentType>({
      query: (body) => {
        return {
          url: `tibberPaymentType`,
          method: "POST",
          body,
        }
      },
      invalidatesTags: ["Customer"],
    }),
    customerNewDeal: builder.mutation<TempDealData, CustomerNewDealSubmission>({
      query: (body) => {
        return {
          url: `customerNewDeal`,
          method: "POST",
          body,
        }
      },
      invalidatesTags: ["Customer"],
    }),
    apiCustomerGetExtendableContracts: builder.query<
      ApiCustomerGetExtendableContracts,
      string
    >({
      query: (cid: string) => `apiCustomerGetExtendableContracts/?cid=${cid}`,
      providesTags: ["ExtendableContracts"],
    }),

    apiCustomerSetExtendableContracts: builder.mutation<
      void,
      ApiCustomerSetExtendableContracts
    >({
      query: (body) => {
        return {
          url: `apiCustomerSetExtendableContracts`,
          method: "POST",
          body,
        }
      },
      invalidatesTags: ["ExtendableContracts"],
    }),
    apiDealGetRegCertNames: builder.query<
      { dealsData: { registrationPlate: string; dealId: string }[] },
      string
    >({
      query: (token: string) => `apiDealGetRegCertNames/?token=${token}`,
      providesTags: ["apiDealGetRegCertNames"],
    }),

    apiDealConfirmRegcerts: builder.mutation<
      void,
      {token: string, toConfirmDeals?: string[]}
    >({
      query: (body) => {
        return {
          url: `apiDealConfirmRegcerts`,
          method: "POST",
          body,
        }
      },
      invalidatesTags: ["apiDealGetRegCertNames"],
    }),
  }),
})

export const {
  useLoginMutation,
  useSignupMutation,
  useDealerAddCustomerCarMutation,
  useBankAccountMutation,
  useCompanyMutation,
  useCustomerQuery,
  useRegistrationMutation,
  useTempDealDataMutation,
  useContractSubmissionMutation,
  useRegCertSubmissionMutation,
  useCustomerNewDealMutation,
  useTibberPaymentTypeMutation,
  useReferralRateOptionMutation,
  useDealerLeadChannelMutation,
  useToggleTaxFreeModeMutation,
  useCustomerAddUserEmailsMutation,
  useApiCustomerGetExtendableContractsQuery,
  useApiCustomerSetExtendableContractsMutation,
  useApiDealGetRegCertNamesQuery,
  useApiDealConfirmRegcertsMutation
} = smartificateApi
