import React from "react"
import { twMerge } from "tailwind-merge"

export const Title = (props: {
  children: JSX.Element | String | String[]
  className?: string
}) => {
  return (
    <h1
      className={twMerge(
        "w-full text-center mt-10 mb-6 text-3xl font-medium leading-tight text-primary",
        props.className
      )}
    >
      {props.children}
    </h1>
  )
}
export const SubTitle = (props: {
  children: JSX.Element | String | String[]
  className?: string
}) => {
  return (
    <h2
      className={twMerge(
        "w-full mb-4 text-xl text-base-content leading-tight ${props.className",
        props.className
      )}
    >
      {props.children}
    </h2>
  )
}
export const SubSubTitle = (props: {
  children: JSX.Element | String | String[]
  className?: string
}) => {
  return (
    <h3
      className={twMerge(
        "w-full text-center mb-4 text-lg leading-tight text-base-content",
        props.className
      )}
    >
      {props.children}
    </h3>
  )
}
