import React from "react"
import { RouteComponentProps } from "@reach/router"
import Layout from "../components/Layout"
import { SubTitle, Title } from "../../anmeldung/components/Titles"
import { useSelector } from "react-redux"
import { RootState } from "../../../state/createStore"


const FertigAenderungen = (props: RouteComponentProps) => {
  const anmeldung = useSelector((state: RootState) => state.anmeldung)
  const registrationPlates = anmeldung.registrationPlates
  return (
    <>
      <Layout>
        <>
          <Title>Geschafft! 🎉</Title>
          <SubTitle className="text-center">
            <>
            {registrationPlates !== undefined && registrationPlates.length > 1 && 
           <>Wir freuen uns sehr, dass du weiterhin mit deinen Fahrzeugen {registrationPlates.join(", ")} dabei bist!</>}
            {registrationPlates !== undefined && registrationPlates.length === 1 && 
           <>Wir freuen uns sehr, dass du weiterhin mit deinen Fahrzeug {registrationPlates[0]} dabei bist!</>}
            {(registrationPlates === undefined || registrationPlates.length === 0) && 
           <>Wir freuen uns sehr, dass du weiterhin mit deinem Fahrzeug dabei bist!</>}
            </>
          </SubTitle>
          <SubTitle className="text-center"><>Du hast Rückfragen oder möchtest sonstige Änderungen vornehmen?
           Wende dich bitte an <a className="link" href="mailto:support@smartificate.de">
            support@smartificate.de
          </a></></SubTitle>
         
        </>
      </Layout>
    </>
  )
}
export default FertigAenderungen
