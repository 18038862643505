import { useState, useEffect } from "react"

export const makeId = (length: number) => {
  var result = ""
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  var charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}
const getWindowDimensions = () => {
  if (typeof window === "undefined") return { width: 800, height: 600 }
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    if (typeof window === "undefined") return () => {}
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return windowDimensions
}

export const makeCustomerId = () => {
  var result = ""
  var characters = "0123456789"
  var charactersLength = characters.length
  for (var i = 0; i < 9; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return "SMT-" + result
}

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms))
