exports.components = {
  "component---src-pages-anmeldung-tsx": () => import("./../../../src/pages/anmeldung/[...].tsx" /* webpackChunkName: "component---src-pages-anmeldung-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-energiewende-tsx": () => import("./../../../src/pages/energiewende.tsx" /* webpackChunkName: "component---src-pages-energiewende-tsx" */),
  "component---src-pages-fahrzeugschein-tsx": () => import("./../../../src/pages/fahrzeugschein.tsx" /* webpackChunkName: "component---src-pages-fahrzeugschein-tsx" */),
  "component---src-pages-flotten-upload-tsx": () => import("./../../../src/pages/flotten-upload.tsx" /* webpackChunkName: "component---src-pages-flotten-upload-tsx" */),
  "component---src-pages-gewinnspiel-teilnahmebedingungen-tsx": () => import("./../../../src/pages/Gewinnspiel-Teilnahmebedingungen.tsx" /* webpackChunkName: "component---src-pages-gewinnspiel-teilnahmebedingungen-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-ksr-tsx": () => import("./../../../src/pages/ksr.tsx" /* webpackChunkName: "component---src-pages-ksr-tsx" */),
  "component---src-pages-kundenbereich-tsx": () => import("./../../../src/pages/kundenbereich/[...].tsx" /* webpackChunkName: "component---src-pages-kundenbereich-tsx" */),
  "component---src-pages-lenz-tsx": () => import("./../../../src/pages/lenz.tsx" /* webpackChunkName: "component---src-pages-lenz-tsx" */),
  "component---src-pages-presse-tsx": () => import("./../../../src/pages/presse.tsx" /* webpackChunkName: "component---src-pages-presse-tsx" */),
  "component---src-pages-widerrufsrecht-tsx": () => import("./../../../src/pages/widerrufsrecht.tsx" /* webpackChunkName: "component---src-pages-widerrufsrecht-tsx" */),
  "component---src-pages-xmas-tsx": () => import("./../../../src/pages/xmas.tsx" /* webpackChunkName: "component---src-pages-xmas-tsx" */)
}

