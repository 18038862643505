import React from "react"
import Layout from "../components/Layout"
import { RouteComponentProps } from "@reach/router"
import { SubSubTitle, Title } from "../components/Titles"
import Button from "../components/Button"
import { navigate } from "gatsby"
import { anmeldungRoutes } from "../anmeldungRouter"
import {
  useApiDealGetRegCertNamesQuery,
  useApiDealConfirmRegcertsMutation,
} from "../../../services/smartificateApi"
import Loading from "../components/Loading"
import Fehler from "../components/Fehler"
import queryString from "query-string"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCar,
  faMinusCircle,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons"
import { useAppDispatch } from "../../../state/createStore"
import { setToConfirmRegistrationPlates } from "../../../state/anmeldung/anmeldungSlice"

const VertragAktualisierung = (props: RouteComponentProps) => {
  const dispatch = useAppDispatch()
  const [skip, setSkip] = React.useState(true)
  const [token, setToken] = React.useState("")
  const [disabledDealsIdxs, setDisabledDealsIdxs] = React.useState<Set<number>>(
    new Set()
  )

  const { data, isLoading, isError } = useApiDealGetRegCertNamesQuery(token, {
    skip,
  })

  React.useEffect(() => {
    if (props?.location?.search) {
      const utmParams = queryString.parse(props.location.search)

      if (utmParams.token && typeof utmParams.token === "string") {
        setToken(utmParams.token)
        setSkip(false)
      } else {
        navigate(anmeldungRoutes.fehler)
      }
    } else {
      navigate(anmeldungRoutes.fehler)
    }
  }, [])

  const [confirmRegcerts, { isLoading: isSubmissionLoading }] =
    useApiDealConfirmRegcertsMutation()

  const multipleCars = data && data?.dealsData && data?.dealsData.length > 1

  const onSubmit = async () => {
    if (!data || !data.dealsData || data.dealsData.length === 0) {
      return
    }
    const toConfirmDeals = data.dealsData.filter((d, idx) =>
      !disabledDealsIdxs.has(idx)
    )

    await confirmRegcerts({
      token,
      toConfirmDeals: toConfirmDeals.map((d) => d.dealId),
    })
      .unwrap()
      .then(async () => {
        navigate(anmeldungRoutes.fertigAenderungen)
      })
      .catch(() => {
        navigate(anmeldungRoutes.fehler)
      })
  }

  React.useEffect(() => {
    if (data && data.dealsData && data.dealsData.length > 0) {
      const notDisabledDeals = data.dealsData.filter((d, idx) =>
      !disabledDealsIdxs.has(idx)
    )
      dispatch(setToConfirmRegistrationPlates(notDisabledDeals.map((d) => d.registrationPlate)))
    }
  }, [data, disabledDealsIdxs, dispatch])

  // use effect when data arrived, if not multiple cars, right away submit
  React.useEffect(() => {
    if (data && data.dealsData && data.dealsData.length === 1) {
      onSubmit()
    }
  }, [data])

  const NoDealsToExtend = (props: RouteComponentProps) => {
    return (
      <Layout>
        <>
          <Title className="text-center">Hoppala</Title>
          <SubSubTitle className="text-center">
            <>
              Deine Anmeldungen sind bereits bestätigt. Melde Dich gerne bei uns
              unter{" "}
              <a href="mailto:support@smartificate.de" className="link">
                support@smartificate.de
              </a>{" "}
              sofern du Fragen zu Deiner Anmeldung hast.
            </>
          </SubSubTitle>
          <Button
            text={`Zum Kundenbereich`}
            onClick={() => navigate("/kundenbereich")}
            className="w-full mx-0"
          
          />
        </>
      </Layout>
    )
  }

  return (
    <>
      <Layout>
        {isLoading || isSubmissionLoading ? (
          <Loading />
        ) : isError || !data ? (
          <Fehler />
        ) : data.dealsData.length === 0 ? (
          <NoDealsToExtend />
        ) : (
          <>
            <Title>Sind Deine Daten aktuell?</Title>
            {multipleCars ? (
              <>
                <SubSubTitle className="text-left">
                  Wir freuen uns, dass Du folgende Fahrzeuge bei uns angemeldet
                  hast:
                </SubSubTitle>
                <ul className="flex flex-col items-start mb-3">
                  {data?.dealsData?.map((deal, idx) => (
                    <li className="mb-2 inline-block text-left flex flex-row items-center">
                      <div
                        className={`card border p-2 cursor-default flex flex-row items-center overflow-auto ${
                          disabledDealsIdxs.has(idx)
                            ? "border-gray-400 text-gray-400"
                            : ""
                        }`}
                      >
                        <FontAwesomeIcon icon={faCar} className={`mr-3`} />
                        <span
                          className={
                            disabledDealsIdxs.has(idx) ? "line-through" : ""
                          }
                        >
                          {deal.registrationPlate}
                        </span>
                      </div>
                      {disabledDealsIdxs.has(idx) ? (
                        <div className="flex flex-col items-start">
                          <div
                            className="tooltip tooltip-accent tooltip-right cursor-pointer"
                            data-tip="Fahrzeug wieder anmelden"
                            onClick={() => {
                              const newSet = new Set([
                                ...disabledDealsIdxs,
                                idx,
                              ])
                              newSet.delete(idx)
                              return setDisabledDealsIdxs(newSet)
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faPlusCircle}
                              className="ml-3 text-green-400"
                              size="lg"
                            />
                          </div>
                        </div>
                      ) : (
                        <div
                          className="tooltip tooltip-accent tooltip-right cursor-pointer"
                          data-tip="Fahrzeug abmelden"
                          onClick={() =>
                            setDisabledDealsIdxs(
                              new Set([...disabledDealsIdxs, idx])
                            )
                          }
                        >
                          <FontAwesomeIcon
                            icon={faMinusCircle}
                            className="ml-3"
                            size="lg"
                          />
                        </div>
                      )}
                    </li>
                  ))}
                  {disabledDealsIdxs.size > 0 && (
                    <span className="card rounded-md border p-2">
                      Sofern Du einen aktualisierten Fahrzeugschein übermitteln
                      möchtest, sende diesen bitte an{" "}
                      <a href="mailto:support@smartificate.de" className="link">
                        support@smartificate.de
                      </a>
                    </span>
                  )}
                </ul>
              </>
            ) : (
              <>
                <SubSubTitle className="text-left">
                  <>
                    Wir freuen uns, dass Du für Dein Fahrzeug{" "}
                    <span className="text-bold whitespace-nowrap">
                      ({data && data.dealsData[0].registrationPlate.toString()})
                    </span>{" "}
                    bereits die Auszahlung Deiner THG-Prämie erhalten hast.
                  </>
                </SubSubTitle>
              </>
            )}
            {/* Now the contract or confirmation part */}
            {multipleCars ? (
              // Multiple deals
              <div className="">
                <SubSubTitle className="text-left">
                  Hiermit bestätige ich, dass die hochgeladenen Fahrzeugscheine
                  der oben genannten Fahrzeuge weiterhin aktuell sind.
                </SubSubTitle>
                <Button
                  text={`Bestätigen`}
                  onClick={onSubmit}
                  classes={`mx-0 px-2 text-black disabled:bg-opacity-70 disabled:cursor-not-allowed w-full`}
                  disabled={disabledDealsIdxs.size === data?.dealsData?.length}
                />
              </div>
            ) : (
              // only one deal
              <div className="">
                <SubSubTitle className="text-left">
                  Bitte bestätige uns kurz, dass es keine Änderungen an Deinem
                  hochgeladenen Fahrzeugschein gab, und Du weiterhin der Halter
                  des Fahrzeuges bist.
                </SubSubTitle>
                <Button
                  text="Bestätigen"
                  onClick={onSubmit}
                  classes={`mx-0 px-2 text-black disabled:bg-opacity-70 disabled:cursor-not-allowed w-full`}
                  disabled={disabledDealsIdxs.values.length === data?.dealsData?.length}
                />
              </div>
            )}
          </>
        )}
      </Layout>
    </>
  )
}

export default VertragAktualisierung
