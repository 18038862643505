import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import React from "react"
import { RootState, useAppDispatch } from "./createStore"
// import type { RootState } from "../state/createStore"

export type AuthState = LoggedOut | Waiting | User

export type LoggedOut = "LoggedOut"
export type Waiting = "Waiting"
export type Customer = "customer"
export type Admin = "admin"

export type UserRole = Customer | Admin
export type User = {
  userType: UserRole
  token: string
}

const slice = createSlice({
  name: "auth",
  initialState: "LoggedOut" as AuthState,
  reducers: {
    setAuthState: (state, action: PayloadAction<AuthState>) => {
      if (isUser(action.payload)) {
        window.localStorage.setItem("user", JSON.stringify(action.payload))
      } else window.localStorage.removeItem("user")
      return action.payload
    },
  },
})

export const { setAuthState } = slice.actions

export default slice.reducer

export const isLoggedIn = (state: RootState): boolean => {
  return (state.auth as User).token !== undefined
}
export const isUser = (auth: AuthState): auth is User => {
  return (auth as User).token !== undefined
}

export const AuthWrapper = ({ element }) => {
  const isBrowser = () => typeof window !== "undefined"
  const dispatch = useAppDispatch()
  React.useEffect(() => {
    if (isBrowser() && window.localStorage.getItem("user")) {
      const user: User = JSON.parse(window.localStorage.getItem("user"))
      dispatch(setAuthState(user))
    }
  }, [])
  return <>{element}</>
}
