import React from "react"
import { RouteComponentProps } from "@reach/router"
import Layout from "./Layout"
import { SubTitle } from "../../anmeldung/components/Titles"

const Loading = (props: RouteComponentProps) => {
  return (
    <Layout>
      <>
        <SubTitle className="text-center">
          Einen kleinen Moment bitte...
        </SubTitle>
        <div className=" flex justify-center items-center">
          <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-primary"></div>
        </div>
      </>
    </Layout>
  )
}
export default Loading
