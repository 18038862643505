import {
  configureStore,
  MiddlewareAPI,
  isRejectedWithValue,
  Middleware,
} from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/dist/query/react"
import { useDispatch } from "react-redux"
// import flowReducer from "../components/Flow/FlowSlice"
import authReducer from "./authSlice"
import anmeldungReducer from "./anmeldung/anmeldungSlice"
import { smartificateApi } from "../services/smartificateApi"

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these use matchers!
    if (isRejectedWithValue(action)) {
      console.warn("We got a rejected action!")
      // toast.warn({ title: "Async error!", message: action.error.data.message })
    }

    return next(action)
  }
export const store = configureStore({
  reducer: {
    // flow: flowReducer,
    anmeldung: anmeldungReducer,
    [smartificateApi.reducerPath]: smartificateApi.reducer,
    auth: authReducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(smartificateApi.middleware),
  // .concat(rtkQueryErrorLogger),
})

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>() // Export a hook that can be reused to resolve types
