/**
 * Smooth scrolling onClick event handler
 * @param {string} selector argument will be passed to `querySelector`, usually an HTML id
 * @param {string} [blockPosition='start'] argument will be used to determine position where will be scrolled to (start, center, end, nearest)
 * @returns {boolean} false if `document.querySelector` doesn't find a match, otherwise true
 */
export const scrollTo = (selector: string, offset?: number): boolean => {
  var element = document.querySelector(selector)
  var headerOffset = offset ? offset : 0

  if (element) {
    const elementPosition = element.getBoundingClientRect().top
    const offsetPosition = elementPosition - headerOffset
    // element.scrollIntoView({
    //   behavior: 'smooth',
    //   block: blockPosition,
    //   alignToTop: true
    // });
    window.scrollBy({
      top: offsetPosition,
      behavior: "smooth",
    })
    return true
  }

  if (process.env.NODE_ENV !== "production") {
    console.warn(
      "gatsby-plugin-smoothscroll:\n The selector: '%s' wasn't found in the document.\n Make sure you pass in a valid CSS selector string.",
      selector
    )
  }

  return false
}
export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" })
}

export default scrollTo
