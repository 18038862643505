import React from "react"
import { motion } from "framer-motion"
import { scrollToTop } from "../../../helpers/ScrollTo"

const Layout = (props: {
  children?: JSX.Element | JSX.Element[]
  fullscreen?: boolean
}) => {
  React.useEffect(() => {
    scrollToTop()
  }, [])
  return (
    <>
      <motion.div
        initial={{ opacity: 0, x: 200 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -200 }}
        transition={{
          type: "spring",
          mass: 0.35,
          stiffness: 75,
          duration: 0.3,
        }}
      >
        <div
          className={props.fullscreen ? "" : "max-w-md mx-auto px-2"}
          style={{ minHeight: "350px" }}
        >
          {props.children}
        </div>
      </motion.div>
    </>
  )
}
export default Layout
