import queryString from "query-string"
import { LeadChannel, RateOption } from "../../apiHelpers/types"

//////////////////////////////////////////////////////////////////////////////////////////
//// Config Stuff
////////////////////////////////////////////////////////////////////////////////////////

// order does matter! dealerId should be before facebook etc so that dealers using their fbclid etc
// are not considered as ours
const paidUtms = [
  "referral",
  "dealerId",
  "facebook",
  "google",
  "gclid",
  "wbraid",
  "emid",
]
// Thes are tracked as they are added as e.g. /?source=DeutscheStartups
const affiliateInternalNames = [
  "Lenz",
  "Yannic",
  "tibber",
  "neuwagen-online",
  "emilfrey",
  "ksr",
  "wechselpilot",
]
const bonusNames = ["xmas2", "xmas", "neujahr", "aktionsbonus", "pflegedienste"]

export const paidUtmToReturnVal = (
  channel: string,
  paidUtm: string
): LeadChannel => {
  switch (channel) {
    // this leadChannel is later overwritten by the one loaded from hubspot
    // but still used to load the dealerId from localstorage
    case "dealerId":
      const utms = queryString.parse(paidUtm)
      // always include "uppr" in the channel name!!!
      return {
        channel: "dealer",
        rateOptions: channelToRate.default,
        utm: paidUtm,
        dealerId: utms.dealerId ? utms.dealerId.toString() : undefined,
      }
    case "facebook":
      return {
        channel: "facebook",
        rateOptions: channelToRate.default,
        utm: paidUtm,
      }
    case "google":
    case "gclid":
    case "wbraid":
      return {
        channel: "google",
        rateOptions: channelToRate.default,
        utm: paidUtm,
      }
    case "emid":
      const emidUtms = queryString.parse(paidUtm)
      // always include "uppr" in the channel name!!!
      if (emidUtms.puid === "2025") {
        return {
          channel: "uppr-Jakob-Reinartz", // has to include "uppr" for uppr tracking to trigger
          rateOptions: channelToRate.default,
          emid: emidUtms.emid ? emidUtms.emid.toString() : undefined,
          puid: emidUtms.puid ? emidUtms.puid.toString() : undefined,
          subid: emidUtms.subid ? emidUtms.subid.toString() : undefined,
          display:
            "Zusätzlich erhältst Du einen 15€ Bonus dank Reinartz & Berndt!",
          displayShort: "Reinartz & Berndt Bonus",
          bonus: 15,
          utm: paidUtm,
        }
      }
      if (emidUtms.puid === "2017") {
        return {
          channel: "uppr-efahrer", // has to include "uppr" for uppr tracking to trigger
          rateOptions: channelToRate.efahrer,
          emid: emidUtms.emid ? emidUtms.emid.toString() : undefined,
          puid: emidUtms.puid ? emidUtms.puid.toString() : undefined,
          subid: emidUtms.subid ? emidUtms.subid.toString() : undefined,
          displayFrontpage:
            "Erhalte für kurze Zeit 150€ jährlich dank unserem EFAHRER-Bonus! 🎉",
          displayContractPage:
            "Erhalte für kurze Zeit 150€ jährlich dank unserem EFAHRER-Bonus! 🎉",
          utm: paidUtm,
        }
      }
      if (emidUtms.puid === "1261") {
        return {
          channel: "uppr-mitgliederBenefits", // has to include "uppr" for uppr tracking to trigger
          rateOptions: channelToRate.mitgliederBenefits,
          emid: emidUtms.emid ? emidUtms.emid.toString() : undefined,
          puid: emidUtms.puid ? emidUtms.puid.toString() : undefined,
          subid: emidUtms.subid ? emidUtms.subid.toString() : undefined,
          displayFrontpage:
            "Erhalte für kurze Zeit 150€ jährlich dank unserem MitgliederBenefits-Bonus! 🎉",
          displayContractPage:
            "Erhalte für kurze Zeit 150€ jährlich dank unserem MitgliederBenefits-Bonus! 🎉",
          utm: paidUtm,
        }
      }
      if (emidUtms.puid === "1112") {
        return {
          channel: "uppr-bsw", // has to include "uppr" for uppr tracking to trigger
          rateOptions: channelToRate.bsw,
          emid: emidUtms.emid ? emidUtms.emid.toString() : undefined,
          puid: emidUtms.puid ? emidUtms.puid.toString() : undefined,
          subid: emidUtms.subid ? emidUtms.subid.toString() : undefined,
          displayFrontpage:
            "Erhalte für kurze Zeit 150€ jährlich dank unserem BSW-Bonus! 🎉",
          displayContractPage:
            "Erhalte für kurze Zeit 150€ jährlich dank unserem BSW-Bonus! 🎉",
          utm: paidUtm,
        }
      }
      if (emidUtms.puid === "2416") {
        return {
          channel: "uppr-FutureBens", // has to include "uppr" for uppr tracking to trigger
          rateOptions: channelToRate.futureBens,
          emid: emidUtms.emid ? emidUtms.emid.toString() : undefined,
          puid: emidUtms.puid ? emidUtms.puid.toString() : undefined,
          subid: emidUtms.subid ? emidUtms.subid.toString() : undefined,
          displayFrontpage:
            "Erhalte für kurze Zeit 150€ jährlich dank unserem FutureBens-Bonus! 🎉",
          displayContractPage:
            "Erhalte für kurze Zeit 150€ jährlich dank unserem FutureBens-Bonus! 🎉",
          utm: paidUtm,
        }
      }
      return {
        channel: "uppr", // has to include "uppr" for uppr tracking to trigger
        rateOptions: channelToRate.default,
        emid: emidUtms.emid ? emidUtms.emid.toString() : undefined,
        puid: emidUtms.puid ? emidUtms.puid.toString() : undefined,
        subid: emidUtms.subid ? emidUtms.subid.toString() : undefined,
        utm: paidUtm,
      }
    case "DeutscheStartups":
      return {
        channel: "DeutscheStartups",
        rateOptions: channelToRate.default,
        display:
          "Zusätzlich erhältst Du 20€ Bonus als Hörer von deutsche-startups.de!",
        displayShort: "Deutsche Startups",
        bonus: 20,
        utm: paidUtm,
      }
    case "faz":
      return {
        channel: "faz",
        rateOptions: channelToRate.default,
        display: "Zusätzlich erhältst Du einen 10€ Bonus als Leser der FAZ! 🎉",
        displayShort: "FAZ Bonus",
        displayFrontpage: "10€ Bonus freigeschaltet! 🎉",
        bonus: 10,
        utm: paidUtm,
      }

    case "ReinartzBerndt":
      return {
        channel: "ReinartzBerndt",
        rateOptions: channelToRate.default,
        utm: paidUtm,
      }
    case "Lenz":
      return {
        channel: "Lenz20201113",
        rateOptions: channelToRate.Lenz,
        display: "Zusätzlich erhältst Du einen 10€ Bonus als Freund von Lenz!",
        displayFrontpage:
          "Als Freund von Lenz erhältst du 150€ jährlich + einen einmaligen 10€ Bonus! 🎉",
        displayContractPage:
          "Als Freund von Lenz erhältst du 150€ jährlich + einen einmaligen 10€ Bonus! 🎉",
        displayShort: "Lenz",
        bonus: 10,
        utm: paidUtm,
      }
    case "neujahr":
      return {
        channel: "neujahr",
        rateOptions: channelToRate.neujahr,
        displayFrontpage:
          "Erhalte für kurze Zeit 150€ dank unserem Neujahresbonus! 🎉",
        displayContractPage:
          "In den Folgejahren erhältst Du immer automatisch unsere Neukundenkonditionen, sofern diese steigen. 🎉",
        // displayShort: "Weihnachts-Bonus",
        // bonus: 50,
        utm: paidUtm,
      }
    case "aktionsbonus":
      return {
        channel: "aktionsbonus",
        rateOptions: channelToRate.neujahr,
        displayFrontpage:
          "Erhalte für kurze Zeit 150€ dank unserem Aktionsbonus! 🎉",
        displayContractPage:
          "In den Folgejahren erhältst Du immer automatisch unsere Neukundenkonditionen, sofern diese steigen. 🎉",
        // displayShort: "Weihnachts-Bonus",
        // bonus: 50,
        utm: paidUtm,
      }

    case "pflegedienste":
      return {
        channel: "pflegedienste",
        rateOptions: channelToRate.neujahr,
        displayFrontpage:
          "Erhalte exklusiv 150€ dank unserem Pflegedienst-Bonus! 🎉",
        displayContractPage:
          "In den Folgejahren erhältst Du immer automatisch unsere Neukundenkonditionen, sofern diese steigen. 🎉",
        utm: paidUtm,
      }
    case "tibber":
      return {
        channel: "tibber",
        rateOptions: channelToRate.tibber,
        // displayContractPage:
        // "Hierbei handelt es sich um eine Rechnungsgutschrift, welche dir direkt von Tibber gutgeschrieben wird.",
        // agbFilename: "AGB_smartificate_Tibber.pdf",
        utm: paidUtm,
        dealerId: "4936901",
      }
    case "Yannic":
      return {
        channel: "Yannic20201113",
        rateOptions: channelToRate.Lenz,
        display:
          "Zusätzlich erhältst Du einen 10€ Bonus als Freund von Yannic!",
        displayFrontpage:
          "Als Freund von Yannic erhältst du 150€ jährlich + einen einmaligen 10€ Bonus! 🎉",
        displayContractPage:
          "Als Freund von Yannic erhältst du 150€ jährlich + einen einmaligen 10€ Bonus! 🎉",
        displayShort: "Yannic",
        bonus: 10,
        utm: paidUtm,
      }
    case "emilfrey":
      return {
        channel: "EmilFrey20150116",
        rateOptions: channelToRate.emilfrey,
        display: "Erhalte exklusiv 150€ jährlich als Kunde von Emil Frey! 🎉",
        displayFrontpage:
          "Erhalte exklusiv 150€ jährlich als Kunde von Emil Frey! 🎉",
        displayContractPage:
          "Erhalte exklusiv 150€ jährlich als Kunde von Emil Frey! 🎉",
        displayShort: "Emil Frey Bonus",
        utm: paidUtm,
        dealerId: "937401",
      }
    case "wechselpilot":
      return {
        channel: "wechselpilot20150426",
        rateOptions: channelToRate.wechselpilot,
        utm: paidUtm,
        dealerId: "2703419",
      }
    case "ksr":
      return {
        channel: "ksr-webseite",
        rateOptions: channelToRate.ksr,
        display: "Diese Rate erhältst Du exklusiv als KSR Aktion! 🎉",
        displayFrontpage: "KSR Aktions-Bonus freigeschaltet! 🎉",
        utm: paidUtm,
        dealerId: "2703419",
      }
    case "neuwagen-online":
      return {
        channel: "NeuwagenOnline20230112",
        rateOptions: channelToRate.neuwagenOnline,
        display:
          "Erhalte exklusiv 150€ jährlich als Kunde von Neuwagen Online! 🎉",
        displayFrontpage:
          "Erhalte exklusiv 150€ jährlich als Kunde von Neuwagen Online! 🎉",
        displayContractPage:
          "Erhalte exklusiv 150€ jährlich als Kunde von Neuwagen Online! 🎉",
        displayShort: "Neuwagen Online Bonus",
        utm: paidUtm,
        dealerId: "595286",
      }
    case "sparneuwagen":
      return {
        channel: "sparneuwagen20150201",
        rateOptions: channelToRate.sparneuwagen,
        display:
          "Erhalte exklusiv 150€ jährlich als Kunde von Sparneuwagen! 🎉",
        displayFrontpage:
          "Erhalte exklusiv 150€ jährlich als Kunde von Sparneuwagen! 🎉",
        displayContractPage:
          "Erhalte exklusiv 150€ jährlich als Kunde von Sparneuwagen! 🎉",
        displayShort: "Sparneuwagen Bonus",
        utm: paidUtm,
      }
    case "referral":
      const utmParams = queryString.parse(paidUtm)
      return {
        channel: "referral",
        rateOptions: channelToRate.default,
        referralFrom: utmParams.referral
          ? utmParams.referral.toString()
          : undefined,
        display:
          "Zusätzlich erhältst Du einen 10€ Bonus für die Freundschaftswerbung.",
        displayShort: "Freunde-Bonus",
        displayFrontpage: "10€ Freunde-Bonus freigeschaltet! 🎉",
        bonus: 10,
        utm: paidUtm,
      }
    case "flensburg-energie":
      return {
        channel: "flensburg-energie",
        rateOptions: channelToRate["flensburg-energie"],
        utm: paidUtm,
      }
    default:
      return {
        channel: "unknown",
        rateOptions: channelToRate.default,
        utm: paidUtm,
      }
  }
}
export const defaultLeadChannel = (): LeadChannel =>
  paidUtmToReturnVal("default", "")

const options150: RateOption[] = [
  {
    rate: 150,
    years: 1,
    categoryRates: { M1: 150, N1: 220, M3: 5000, Scooter: 150, Other: 150 },
  },
  {
    rate: 150,
    years: 2,
    categoryRates: { M1: 150, N1: 220, M3: 5000, Scooter: 150, Other: 150 },
  },
  {
    rate: 150,
    years: 3,
    categoryRates: { M1: 150, N1: 220, M3: 5000, Scooter: 150, Other: 150 },
  },
]

export const channelToRate = {
  default: {
    rateOptionsName: "default20211113",
    options: options150,
    defaultRateIdx: 2,
    bonusMode: false,
  },
  Lenz: {
    rateOptionsName: "LenzRate20150112",
    options: options150,
    defaultRateIdx: 2,
    bonusMode: false,
  },
  efahrer: {
    rateOptionsName: "efahrer20150524",
    options: options150,
    defaultRateIdx: 2,
    bonusMode: false,
  },
  mitgliederBenefits: {
    rateOptionsName: "mitgliederBenefits20150602",
    options: options150,
    defaultRateIdx: 2,
    bonusMode: false,
  },
  bsw: {
    rateOptionsName: "bsf20150815",
    options: options150,
    defaultRateIdx: 2,
    bonusMode: false,
  },
  futureBens: {
    rateOptionsName: "futureBens20150614",
    options: options150,
    defaultRateIdx: 2,
    bonusMode: false,
  },
  sparneuwagen: {
    rateOptionsName: "sparneuwagen20150112",
    options: options150,
    defaultRateIdx: 2,
    bonusMode: false,
  },
  neujahr: {
    rateOptionsName: "neujahr-special-rate",
    options: options150,
    defaultRateIdx: 2,
    bonusMode: false,
  },
  aktionsbonus: {
    rateOptionsName: "aktionsbonus-special-rate",
    options: options150,
    defaultRateIdx: 2,
    bonusMode: false,
  },
  emilfrey: {
    rateOptionsName: "emilfrey-rate",
    options: options150,
    defaultRateIdx: 2,
    bonusMode: false,
  },
  neuwagenOnline: {
    rateOptionsName: "neuwagenOnline-rate",
    options: options150,
    defaultRateIdx: 2,
    bonusMode: false,
  },
  wechselpilot: {
    rateOptionsName: "wechselpilot-rate",
    options: options150,
    defaultRateIdx: 2,
    bonusMode: false,
  },
  ksr: {
    rateOptionsName: "ksr-rate",
    options: options150,
    defaultRateIdx: 2,
    bonusMode: false,
  },
  tibber: {
    rateOptionsName: "tibber",
    options: options150,
    defaultRateIdx: 0,
    bonusMode: false,
  },
  "flensburg-energie": {
    rateOptionsName: "flensburg-energie",
    options: options150,
    defaultRateIdx: 2,
    bonusMode: true,
  },
}

//////////////////////////////////////////////////////////////////////////////////////////
//// Config Stuff END
////////////////////////////////////////////////////////////////////////////////////////
export const extendUtmDictString = (
  // params can bei either raw strings or strigified dicts
  oldUtmDictString?: string,
  newUtmDictString?: string
) => {
  const currentTime = Math.floor(Date.now() / 1000)
  // .filter(x => x) is filtering empty strings
  let oldUtm = {}
  if (oldUtmDictString && oldUtmDictString !== "") {
    if (oldUtmDictString.startsWith("{")) {
      oldUtm = JSON.parse(oldUtmDictString)
    } else {
      // just edge case when an old UTM string comes from HubSpot
      oldUtm[currentTime - 2] = oldUtmDictString
    }
  }
  let newUtm = {}
  if (newUtmDictString && newUtmDictString !== "") {
    if (newUtmDictString.startsWith("{")) {
      newUtm = JSON.parse(newUtmDictString)
      // else when its a new string from url
    } else {
      // prevent overwrite on forwarding pages
      const plusTime = currentTime + 1 in oldUtm ? 2 : 1
      newUtm[currentTime + plusTime] = newUtmDictString
    }
  }
  const mergedDict = { ...oldUtm, ...newUtm }

  // Filter out token UTM from login email
  var filteredMergedDict = Object.fromEntries(
    Object.entries(mergedDict).filter(
      ([k, v]) => !v.includes("token") && !v.includes("dealId")
    )
  )

  return JSON.stringify(
    mergedDict,
    Object.keys(filteredMergedDict).sort().reverse()
  )
}

export const returnLeadChannel = (
  utmDictString: string,
  preferReferral: boolean = true // For firing Lead events, we prefer referral. For rateOptions we dont
): LeadChannel => {
  const currentTime = Math.floor(Date.now() / 1000)

  let utm = {}
  if (utmDictString && utmDictString !== "") {
    if (utmDictString.startsWith("{")) {
      utm = JSON.parse(utmDictString)
    } else {
      // just edge case when an old UTM string comes from HubSpot
      utm[currentTime - 1] = utmDictString
    }
  }

  if (preferReferral) {
    // Always return referral as it overwrites all other
    for (let i = 0; i < Object.keys(utm).length; i++) {
      const currUtmRaw = utm[Object.keys(utm)[i]]
      const currUtms = queryString.parse(currUtmRaw)
      console.log(currUtms)
      if (
        currUtms.referral &&
        typeof currUtms.referral === "string" &&
        currUtms.referral.length === 8
      )
        return paidUtmToReturnVal("referral", currUtmRaw)
    }
  }
  // filter all old leadchannels of elektrobonus
  if (utmDictString.includes("flexMode")) {
    return paidUtmToReturnVal("aktionsbonus", "") // will return 300
  }

  const paidChannels = [...paidUtms, ...affiliateInternalNames]
  // for uppr tracking the paid channels should be ignored
  if (!preferReferral) paidChannels.push(...bonusNames)
  // sort UTMS desc by time (time is  the key)
  const timeSortedKeys = Object.keys(utm).sort().reverse()
  for (let i = 0; i < timeSortedKeys.length; i++) {
    // affiliateInternalNames are stored as UTMs as well e.g. /?source=DeutscheStartups
    const firstPayedChannel = paidChannels.find((channel) =>
      utm[timeSortedKeys[i]].includes(channel)
    )
    if (firstPayedChannel)
      return paidUtmToReturnVal(firstPayedChannel, utm[timeSortedKeys[i]])
  }
  // no payed channel found
  return { channel: "unknown", rateOptions: channelToRate.default }
}

// export const doUTMTracking = (
//   dispatch: ThunkDispatch<any, any, any>,
//   currSearchString?: string
// ) => {
//   let combinedUtmRaw = "{}"

//   combinedUtmRaw = extendUtmDictString(
//     localStorage.getItem("utm")!,
//     currSearchString
//   )
//   if (combinedUtmRaw && combinedUtmRaw !== "{}") {
//     localStorage.setItem("utm", combinedUtmRaw)
//     dispatch(extendUtm(combinedUtmRaw))
//   }

//   console.log("leadchannel", returnLeadChannel(combinedUtmRaw, false))
//   // Load the rateoptions for the session. Has a drfault for the common {} case
//   dispatch(setLeadChannel(returnLeadChannel(combinedUtmRaw, false)))
// }
